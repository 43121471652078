import {
	ActionIcon,
	Button,
	Card,
	CopyButton,
	Group,
	Stack,
	Text,
	TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	IconChevronDown,
	IconChevronUp,
	IconCopy,
	IconEye,
	IconPrompt,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { formatDateTime } from '../../services/utils/helpers';
import AiPrompts from '../ai/prompts';
import Link from '../framework/link';

const Item = ({ collapsed = true, item }) => {
	const [openedAiPrompt, { close: closeAiPrompt, toggle: toggleAiPrompt }] =
		useDisclosure(false);
	const [openedContent, { toggle: toggleContent }] = useDisclosure(collapsed);

	return (
		<Card mb="md" padding="lg" radius="md" shadow="sm" withBorder>
			<Card.Section
				inheritPadding
				mb={openedContent ? 'sm' : ''}
				onClick={toggleContent}
				py="xs"
				withBorder={openedContent}
			>
				<Group justify="space-between">
					<Stack gap={0}>
						<Text size="xs">{item.dataset.name}</Text>
						<Text size="xs">{formatDateTime(item.created_at)}</Text>
					</Stack>

					<ActionIcon color="gray" size="xs" variant="transparent">
						{openedContent ? <IconChevronUp /> : <IconChevronDown />}
					</ActionIcon>
				</Group>
			</Card.Section>

			{openedContent && (
				<>
					<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
						<TypographyStylesProvider>
							<ReactMarkdown>{item.content}</ReactMarkdown>
						</TypographyStylesProvider>
					</Card.Section>

					{openedAiPrompt && item?.prompts?.length && (
						<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
							<AiPrompts
								items={item?.prompts}
								onClose={closeAiPrompt}
								template_id={item.template_id}
							/>
						</Card.Section>
					)}

					<Group grow>
						<CopyButton value={item.content}>
							{({ copied, copy }) => (
								<Button
									color={copied ? 'green' : ''}
									leftSection={<IconCopy />}
									onClick={copy}
									size="xs"
									variant="light"
								>
									{copied ? 'Copied' : 'Copy'}
								</Button>
							)}
						</CopyButton>

						{item?.prompts?.length && (
							<Button
								leftSection={<IconPrompt />}
								onClick={toggleAiPrompt}
								size="xs"
								variant="light"
							>
								Prompt
							</Button>
						)}

						{item?.id && (
							<Button
								component={Link}
								leftSection={<IconEye />}
								size="xs"
								to={`/content_manager/DatasetItem/${item.id}/`}
								variant="light"
							>
								View
							</Button>
						)}
					</Group>
				</>
			)}
		</Card>
	);
};

Item.propTypes = {
	collapsed: PropTypes.bool,
	item: PropTypes.object,
};

export default Item;
