import React from 'react';

import Authentication from '../components/authentication';
import Framework from '../components/framework';
import Spotlight from '../components/framework/spotlight';
import Suspense from '../components/framework/suspense';
import Router from '../components/route';
import NotFound from '../components/route/not_found';
import { AiProvider } from '../context/ai';

const Analysis = React.lazy(() => import('../screens/analysis'));
const AnalysisPrepare = React.lazy(() => import('../screens/analysis/prepare'));
const AnalysisPrint = React.lazy(() => import('../screens/analysis/print'));
const AnalysisStatement = React.lazy(
	() => import('../screens/analysis/statement'),
);
const AnalysisView = React.lazy(() => import('../screens/analysis/view'));
const Case = React.lazy(() => import('../screens/case'));
const CaseAi = React.lazy(() => import('../screens/case/ai'));
const CaseAnalysis = React.lazy(() => import('../screens/case/analysis'));
const CaseView = React.lazy(() => import('../screens/case/view'));
const ContentManager = React.lazy(() => import('../screens/content_manager'));
const ContentManagerHandle = React.lazy(
	() => import('../screens/content_manager/handle'),
);
const ContentManagerList = React.lazy(
	() => import('../screens/content_manager/list'),
);
const ContentManagerStatistics = React.lazy(
	() => import('../screens/content_manager/statistics'),
);
const ContentManagerView = React.lazy(
	() => import('../screens/content_manager/view'),
);
const Gdpr = React.lazy(() => import('../screens/gdpr'));
const Index = React.lazy(() => import('../screens/index'));
const Issue = React.lazy(() => import('../screens/issue'));
const Profile = React.lazy(() => import('../screens/profile'));
const Statistic = React.lazy(() => import('../screens/statistics'));
const StatisticAnalysis = React.lazy(
	() => import('../screens/statistics/analysis'),
);
const StatisticAnalysisCost = React.lazy(
	() => import('../screens/statistics/analysis_cost'),
);
const StatisticFinancial = React.lazy(
	() => import('../screens/statistics/financial'),
);
const StatisticLocationCost = React.lazy(
	() => import('../screens/statistics/location_cost'),
);
const StatisticPartner = React.lazy(
	() => import('../screens/statistics/partner'),
);
const StatisticProductCost = React.lazy(
	() => import('../screens/statistics/product_cost'),
);
const StatisticRetention = React.lazy(
	() => import('../screens/statistics/retention'),
);
const StatisticUser = React.lazy(() => import('../screens/statistics/user'));
const Tool = React.lazy(() => import('../screens/tools'));

const App = () => {
	return (
		<Authentication>
			<AiProvider>
				<Framework>
					<Suspense fallback={<></>}>
						<Router>
							<Index path="/" />
							<Profile path="/profile" />

							<Analysis path="/analysis/" />
							<AnalysisView path="/analysis/view/:id" />
							<AnalysisPrepare path="/analysis/view/:id/prepare" />
							<AnalysisPrint path="/analysis/view/:id/print" />
							<AnalysisStatement path="/analysis/view/:id/statement" />

							<Case path="/case/" />
							<CaseAi path="/case/ai" />
							<CaseAnalysis path="/case/analysis" />
							<CaseView path="/case/view/:id" />

							<ContentManager path="/content_manager" />
							<ContentManagerList path="/content_manager/:model" />
							<ContentManagerHandle path="/content_manager/:model/create" />
							<ContentManagerView path="/content_manager/:model/:id" />
							<ContentManagerHandle path="/content_manager/:model/:id/:action" />
							<ContentManagerStatistics path="/content_manager/:model/statistics" />

							<Issue path="/issues" />

							<Gdpr path="/gdpr/" />

							<Statistic path="/statistics" />
							<StatisticAnalysis path="/statistics/analysis" />
							<StatisticAnalysisCost path="/statistics/analysis_cost" />
							<StatisticLocationCost path="/statistics/location_cost" />
							<StatisticFinancial path="/statistics/financial" />
							<StatisticPartner path="/statistics/partner" />
							<StatisticProductCost path="/statistics/product_cost" />
							<StatisticRetention path="/statistics/retention" />
							<StatisticUser path="/statistics/user" />

							<Tool path="/tools" />

							<NotFound default />
						</Router>
					</Suspense>
				</Framework>

				<Spotlight />
			</AiProvider>
		</Authentication>
	);
};

export default App;
