import {
	ActionIcon,
	AppShell,
	Button,
	Card,
	CopyButton,
	Group,
	List,
	Popover,
	ScrollArea,
	Stack,
	Text,
	Title,
	TypographyStylesProvider,
	VisuallyHidden,
} from '@mantine/core';
import {
	useDebouncedCallback,
	useDisclosure,
	useScrollIntoView,
} from '@mantine/hooks';
import { modals } from '@mantine/modals';
import {
	IconAi,
	IconChevronDown,
	IconChevronUp,
	IconCopy,
	IconInfoCircle,
	IconPrompt,
	IconSend,
	IconTrash,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { AdminContext } from '../../context/admin';
import { AiContext } from '../../context/ai';
import { RequestError } from '../../error/request';
import useForm from '../../hooks/use_form';
import useMutate from '../../hooks/use_mutate';
import useRequest from '../../hooks/use_request';
import { formatDateTime } from '../../services/utils/helpers';
import AsideHeader from '../aside/header';
import Form from '../form';
import Select from '../form/select';
import Submit from '../form/submit';
import Textarea from '../form/textarea';
import Language from '../framework/language';
import Suspense from '../framework/suspense';
import useToast from '../framework/toast';
import AiPrompts from './prompts';

const AiAside = () => {
	const { items } = useContext(AiContext);
	const { scrollIntoView, scrollableRef, targetRef } = useScrollIntoView();

	const handleScroll = useDebouncedCallback(() => {
		scrollIntoView();
	}, 700);

	return (
		<>
			<AsideHeader icon={<IconAi />} title="AI" />

			<AppShell.Section component={ScrollArea} grow my="md" ref={scrollableRef}>
				{Array.from(items)?.map((item, index) => {
					return (
						<React.Fragment key={index}>
							{index + 1 == items.size && (
								<VisuallyHidden ref={targetRef}>Last item</VisuallyHidden>
							)}
							<Item item={item} />
						</React.Fragment>
					);
				})}
			</AppShell.Section>
			<AppShell.Section>
				<Suspense>
					<Request handleScroll={handleScroll} />
				</Suspense>
			</AppShell.Section>
		</>
	);
};

const Item = ({ item }) => {
	const [openedAiPrompt, { close: closeAiPrompt, toggle: toggleAiPrompt }] =
		useDisclosure(false);
	const [openedContent, { toggle: toggleContent }] = useDisclosure(true);

	return (
		<Card mb="md" padding="lg" radius="md" shadow="sm" withBorder>
			<Card.Section
				inheritPadding
				mb={openedContent ? 'sm' : ''}
				onClick={toggleContent}
				py="xs"
				withBorder={openedContent}
			>
				<Group justify="space-between" wrap="nowrap">
					<Text size="xs">{formatDateTime(item.date)}</Text>
					{!openedContent && (
						<Text c="dimmed" ms="sm" size="xs">
							{item.input.substring(0, 30)}...
						</Text>
					)}

					<ActionIcon color="gray" size="xs" variant="transparent">
						{openedContent ? <IconChevronUp /> : <IconChevronDown />}
					</ActionIcon>
				</Group>
			</Card.Section>

			{openedContent && (
				<>
					{item?.input && (
						<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
							<Text fw={700} mb="xs">
								Question
							</Text>
							{item.input}
						</Card.Section>
					)}

					<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
						<Text fw={700} mb="xs">
							Result
						</Text>
						<TypographyStylesProvider>
							<ReactMarkdown>{item.content}</ReactMarkdown>
						</TypographyStylesProvider>
					</Card.Section>

					{openedAiPrompt && item?.prompts?.length && (
						<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
							<AiPrompts
								items={item?.prompts}
								onClose={closeAiPrompt}
								settings={item?.settings}
								template_id={item.template_id}
							/>
						</Card.Section>
					)}

					<Group grow>
						<CopyButton value={item.content}>
							{({ copied, copy }) => (
								<Button
									color={copied ? 'green' : ''}
									leftSection={<IconCopy />}
									onClick={copy}
									size="xs"
									variant="light"
								>
									{copied ? 'Copied' : 'Copy'}
								</Button>
							)}
						</CopyButton>

						{item?.prompts?.length && (
							<Button
								leftSection={<IconPrompt />}
								onClick={toggleAiPrompt}
								size="xs"
								variant="light"
							>
								Prompt
							</Button>
						)}
					</Group>
				</>
			)}
		</Card>
	);
};

const Options = ({ children }) => {
	const { hasOptions, options } = useContext(AiContext);
	if (!hasOptions) return;

	return (
		<Popover position="bottom" shadow="md" width={400} withArrow>
			<Popover.Target>{children}</Popover.Target>
			<Popover.Dropdown>
				<Title mb="xs" size="sm">
					Options
				</Title>
				<List size="xs">
					{Object.values(options)?.map((item, index) => {
						return <List.Item key={index}>{JSON.stringify(item)}</List.Item>;
					})}
				</List>
			</Popover.Dropdown>
		</Popover>
	);
};

const Request = ({ handleScroll }) => {
	const { addItem, hasOptions, options, reset } = useContext(AiContext);
	const {
		constants: { template_types },
		language,
	} = useContext(AdminContext);
	const { methods: formMethods } = useForm();
	const { data: templateResponse } = useRequest('content_manager.list', {
		params: {
			fields: ['id', 'name'],
			filter: {
				destination: { $eq: 'ai' },
				//partner_id: { $eq: options.partner_id },
				type: { $eq: template_types.chat.id },
			},
			limit: 1000,
			model: 'Template',
		},
	});
	const { trigger } = useMutate('ai.query');
	const { errorToast } = useToast();
	const templates = templateResponse?.data;

	const handleReset = () =>
		modals.openConfirmModal({
			children: <Text size="sm">Are you sure you want to reset?</Text>,
			labels: { cancel: 'No', confirm: 'Yes' },
			onConfirm: () => {
				formMethods.resetField('input');
				reset();
			},
			title: 'Reset AI',
		});

	const onSubmit = async (values) => {
		const items_tmp = Object.values(options);

		try {
			const result = await trigger({
				input: values.input,
				items: items_tmp,
				language: language.id,
				save: false,
				template_id: values.template_id,
			});
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			if (result?.data?.messages?.length) {
				addItem({
					content: result.data.messages[0],
					input: values.input,
					prompts: result.data.prompts,
					settings: result.data.settings,
					template_id: values.template_id,
				});
				handleScroll();
				formMethods.resetField('input');
			}
		} catch (error) {
			errorToast({
				description: 'Unable to query the AI, please try again.',
				error,
				title: 'Error',
			});
		}
	};

	return (
		<Form methods={formMethods} onSubmit={onSubmit}>
			<Stack gap="xs">
				<Select
					data={templates?.items?.map((item) => ({
						label: item.name,
						value: `${item.id}`,
					}))}
					defaultValue=""
					label={false}
					name="template_id"
					placeholder="Template"
				/>
				<Textarea
					defaultValue=""
					label={false}
					name="input"
					placeholder="Input"
					rows={5}
				/>
				<Group grow wrap="nowrap">
					<Submit leftSection={<IconSend size={14} />} name="Run" />
					<Language>
						<Button color="gray" variant="light">
							{language.symbol.toUpperCase()}
						</Button>
					</Language>
					{hasOptions && (
						<Options>
							<Button color="gray" variant="light">
								<IconInfoCircle />
							</Button>
						</Options>
					)}
					<Button color="gray" onClick={handleReset} variant="light">
						<IconTrash />
					</Button>
				</Group>
			</Stack>
		</Form>
	);
};

Item.propTypes = {
	item: PropTypes.object,
};

Options.propTypes = {
	children: PropTypes.node,
};

Request.propTypes = {
	handleScroll: PropTypes.func,
};

export default AiAside;
